<template>
    <v-card
        style="display: inline-block; vertical-align: top"
        class="mr-4 mb-2"
        width="260px"
        color="#EAEAEA">
        <v-card-text class="pa-2">
            <h3>
                {{ testGroup.name }}
            </h3>
            <perfect-scrollbar
                class="scroll-height"
                :options="{ suppressScrollX: true }">
                <draggable
                    class="test-group"
                    :value="tests"
                    group="testGroup"
                    :disabled="!canEdit('SampleSetTests')"
                    @change="onCardChanged($event, testGroup.id)">
                    <div v-for="(test, index) in tests" :key="index">
                        <test :test="test" v-on="$listeners" />
                    </div>
                </draggable>
            </perfect-scrollbar>
        </v-card-text>
        <v-card-actions class="justify-center">
            <v-btn
                v-if="canEdit('Tests')"
                color="tertiary"
                dark
                @click="$emit('add', testGroup.id)">
                Add Test
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import Vue from "vue";
import draggable from "vuedraggable";
import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";

Vue.use(PerfectScrollbar);

export default {
    components: {
        draggable,
        test: () => import("./Test"),
    },
    props: {
        testGroup: {
            type: Object,
            default: null,
        },
        tests: {
            type: Array,
            default: () => [],
        },
        filter: {
            type: Object,
            default: null,
        },
    },

    data() {
        return {
            isAdding: false,
        };
    },

    methods: {
        onCardChanged(value, testGroupId) {
            if (!this.isEmpty(value.moved)) {
                this.move(value.moved.element, testGroupId);
                return;
            }
            if (!this.isEmpty(value.added)) {
                this.move(value.added.element, testGroupId);
            }
        },

        async move(test, testGroupId) {
            this.$emit("move", { test, testGroupId });
        },
    },
};
</script>

<style scoped>
    .scroll-height {
        height: 60vh;
    }

    .test-group {
        min-height: 100%;
    }
</style>
